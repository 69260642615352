@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@font-face {
  font-family: Alicante;
  src: url(../public/fonts/fonts1/Montserrat-Light.ttf);
}

@font-face {
  font-family: Alingkar;
  src: url(../public/fonts/font2/alingkar.otf);
}

body{
  background-color: #F4ECE9;
}

.active{
  border-bottom: 2px solid #8E2209;
  width: 40px;
}